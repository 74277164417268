import "../css/main.scss";
import { gsap } from "gsap";

// Copyright date
let date = new Date();
let year = date.getFullYear();
const copyrightDate = document.querySelector(".date");
copyrightDate.innerHTML = year;

// GSAP animation
let tl = gsap.timeline();

tl.from(".content", {
  opacity: 0,
})
  .to(".layer", {
    scaleX: 0,
    duration: 1,
    ease: "power3.inOut",
  })
  .to(".layer", {
    display: "none",
    duration: 0,
  })
  .to(".header__title-studio span", {
    x: 0,
    duration: 1.7,
    ease: "power3.inOut",
  })
  .to(".header__title-arch span", {
    x: 0,
    duration: 1.7,
    delay: -1.5,
    ease: "power3.inOut",
  })
  .from(".line", {
    ease: "power3.inOut",
    scaleX: 0,
  })
  .to(".footer__copyright span", {
    y: 0,
    duration: 1,
    delay: -0.5,
    ease: "power3.inOut",
  })
  .to(".footer__list li span", {
    delay: -0.5,
    y: 0,
    duration: 1,
    stagger: 0.2,
    ease: "power3.inOut",
  })
  .to(".footer__list li a", {
    y: 0,
    duration: 1,
    delay: -0.8,
    stagger: 0.2,
    ease: "power3.inOut",
  });
